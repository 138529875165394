.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: $primarycolor;
}

.marker__item {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.contact__email {
    font-size: 19px;
    color: $primarycolor;
    font-weight: 700;
    line-height: 29px;
    display: block;
    margin-bottom: 25px;

    &:focus,
    &:active,
    &:hover {
        color: $secondarycolor;
    }

    svg {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
}

.contact__phone {
    font-size: 24px;
    color: $secondarycolor;
    font-weight: 700;
    line-height: 29px;
    display: block;
    margin-bottom: 25px;

    &:focus,
    &:active,
    &:hover {
        color: $secondarycolor;
    }

    svg {
        width: $phone-height;
        height: $phone-height;
        margin-right: 20px;
    }
}

.contact__form__inner {
    padding: 66px 66px 50px;
    border-radius: $border-radius;
    background-color: #e8eefc;

    .gfield {
        input,
        select,
        textarea {
            border: 1px solid transparent;
            background-color: #fff;
        }

        textarea {
            max-height: 180px;
        }
    }
}

.contact__form__title {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.contact__company {
    margin-bottom: 2px;
    color: $primarycolor;
    font-weight: 700;

    &.contact__company--footer {
        margin-bottom: 12px;
        font-size: 30px;
    }
}

.contact__address {
    font-size: 18px;
    line-height: 1.889em;

    span {
        display: block;
    }
}

.contact__accessibility {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    line-height: 31px;
}

.contact__icon {
    margin-right: 10px;

    svg {
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__text {
    transition: all 0.3s;
    color: $body-font-color;
}

.contact__details {
    margin-bottom: 20px;
}

.contact__location {
    position: relative;
    margin-bottom: 58px;
    padding-left: 51px;

    .contact__location__marker {
        position: absolute;
        left: 0;
        width: 21px;
        height: 29px;

        &.contact__location__marker--footer {
            width: 30px;
            height: 37px;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.contact__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
        .contact__text {
            color: $primarycolor;
        }
    }
}

.contact__line__col {
    width: 75px;
    font-weight: bold;
}

.contact__image {
    max-width: 90%;
    margin-top: 30px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .contact__main {
        margin-bottom: 40px;
    }

    .contact__company {
        &.contact__company--footer {
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 767px) {
    .contact__form__inner {
        padding: 30px;
        border-radius: 30px;
    }

    .contact__location {
        margin-bottom: 30px;
    }

    .contact__main {
        margin-bottom: 20px;
    }

    .contact__image {
        max-width: 100%;
        margin-top: 35px;
    }

    .acf-map {
        height: 300px;
    }

    .footer .contact__location {
        justify-content: center;
        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-bottom: 10px;
    }

    .contact__location .contact__location__marker.contact__location__marker--footer {
        position: relative;
        margin-bottom: 10px;
    }

    .footer .footer__top .footer__top__logo {
        max-width: 240px;
    }
}

@media screen and (max-width: 575px) {
    .contact__form__inner {
        padding: 31px 35px;
    }

    .acf-map {
        height: 240px;
    }

    .contact__company {
        &.contact__company--footer {
            font-size: 21px;
        }
    }
}

@media screen and (max-width: 450px) {
    .footer {
        .contact__company {
            &.contact__company--footer {
                font-size: 18px;
            }
        }

        .contact__address {
            font-size: 16px;
        }
    }
}
