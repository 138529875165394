/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #31306f;
$secondarycolor: #ac6788;
$thirdcolor: #006190;

$font-primary: 'Comfortaa', cursive;
$body-font-size: 17px;
$body-font-weight: 300;
$body-line-height: 1.647em;
$body-font-color: #505050;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: $font-primary;
$headings-font-weight: 700;
$headings-line-height: 1.3;
$headings-color: $primarycolor;

// Header:
$nav-height: 90px;
$phone-height: 40px;
$logo-height: 160px;
$header-padding: 40px;
$header-top-height: 0;
$header-bottom-height: $logo-height; // use logo height since its more than nav + phone
$header-height: $header-bottom-height + $header-top-height + (2*$header-padding);

$border-radius: 50px;
$image-radius: 30px;
$block-radius: 15px;
$small-radius: 5px;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 36px;
$h2-font-size: 28px;
$h3-font-size: 26px;
$h4-font-size: 24px;
$h5-font-size: 22px;
$h6-font-size: 20px;
