.front__news {
    position: relative;
    padding: 0 0 170px;

    .front__news__circle {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-65%);
        transform-origin: right;
        right: 0;
        max-width: 100%;
        z-index: -1;

        svg {
            max-width: 100%;
        }
    }
}

@media (max-width: 1199px) {
    .front__news {
        padding: 0 0 80px;
    }
}

@media (max-width: 991px) {
    .front__news {
        padding: 0 0 40px;
    }
}

@media (max-width: 767px) {

}

@media screen and (max-width: 640px) {
    .front__news .page__header .page__title--news {
        font-size: 26px;
        line-height: normal;
    }
}
