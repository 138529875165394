.footer {
    color: $body-font-color;
    transition: all 0.3s;

    .footer__socials__column {
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }

    .footer__socials__items {
        display: inline-flex;
    }

    .footer__inner {
        background-color: #eef7df;
        margin-top: -5px;
    }
}

.footer__top {
    padding: 24px 0 80px;

    .footer__top__logo {
        align-self: center;
        margin: 0 auto;
        text-align: center;
        max-width: 340px;

        svg {
            max-width: 100%;
        }
    }
}

.footer__mid {
    padding-bottom: 50px;
}

.footer__bottom {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    opacity: 0.8;
    padding: 30px 0;
    border-top: 1px solid rgba(#fff, 0.2);

    a {
        &:hover {
            opacity: 0.6;
        }
    }

    .copyright__left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        margin: 30px 0;

        a {
            color: $body-font-color;
        }

        .copyright__left__text {
            font-size: 14px;
        }
    }

    .copyright__right {
        justify-content: space-between;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .copyright__right__image {
            max-height: 95px;
            margin: 15px 0;

            &.copyright__right__image--knov {
                max-height: 65px;
            }
        }
    }
}

.footer__bottom__menu {
    font-size: 14px;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;

    li {
        position: relative;
        padding: 0 10px;

        a {
            color: $body-font-color;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &:not(:last-child)::before {
            display: inline-block;
            content: "";
            height: 17px;
            width: 1px;
            position: absolute;
            top: 4px;
            background-color: #c6d3b0;
            right: 0;
        }
    }
}

@media (max-width: 1199px) {

}

@media (max-width: 991px) {
    .footer__top {
        .footer__top__logo {
            margin-bottom: 15px;
        }
    }

    .footer__bottom {
        .copyright__right {
            justify-content: flex-start;

            .copyright__right__image {
                margin-right: 30px;
                margin-top: 0;
            }
        }

        .copyright__left {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }

    .footer__mid {
        padding-bottom: 0;
    }

    .footer {
        .footer__socials__column {
            margin-top: 15px;
        }
    }
}

@media (max-width: 767px) {
    .footer__top {
        padding: 37px 0 15px 0;
    }

    .footer__bottom {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        padding: 25px 0;
    }

    .copyright__right {
        margin-top: 15px;
    }

    .footer .footer__bottom .copyright__left {
        justify-content: center;
        align-items: center;
    }

    .footer .footer__bottom__menu {
        text-align: center;
        justify-content: center;
    }

    .footer__bottom .copyright__left .copyright__left__text {
        text-align: center;
    }

    .footer__bottom .copyright__right {
        justify-content: center !important;
        flex-direction: column;

        img {
            margin-right: 0 !important;
        }
    }
}

@media (max-width: 575px) {
}
