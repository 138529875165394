.front__intro {
    padding: 195px 0 150px 0;

    h2 {
        font-size: 40px;
        color: $primarycolor;
    }

    p {
        font-size: 20px;
        line-height: 1.7;
    }
}

.front__intro__inner {
    padding: 0 90px 0 120px;
    display: flex;
}

.front__intro__content {
    padding-right: 135px;
}

.front__intro__klantenvertellen {
    margin-top: 50px;
}

@media (max-width: 1500px) {
    .front__intro__inner {
        padding: 0;
    }
}

@media (max-width: 1199px) {
    .front__intro__content {
        padding-right: 50px;
    }
}

@media (max-width: 991px) {
    .front__intro {
        h2 {
            font-size: 34px;
        }

        p {
            font-size: 18px;
        }
    }
}

@media (max-width: 767px) {
    .front__intro {
        padding: 50px 0 30px 0;

        h2 {
            font-size: 26px;
        }

        p {
            font-size: 17px;
        }
    }

    .front__intro__inner {
        flex-direction: column;
    }

    .front__intro__content {
        padding-right: 0;
    }

    .front__intro__klantenvertellen {
        margin-top: 17px;
    }
}
