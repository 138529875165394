.single__img__nieuws {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin-bottom: 25px;
    overflow: hidden;

    h1 {
        position: absolute;
        bottom: 30px;
        left: 0;
        color: #fff;
        padding: 0 30px;
        width: 100%;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.36);
        margin-bottom: 0;
    }

    img {
        border-radius: $image-radius;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .single__img__nieuws {
        h1 {
            bottom: 20px;
        }
    }
}

@media screen and (max-width: 575px) {

}
