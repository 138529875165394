body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
}

h2,
h3,
h4,
h5,
h6 {
    color: $secondarycolor;
}

strong {
    font-weight: 700;
}

.card-body {
    a {
        font-weight: 700;
    }
}

.fixed__phone {
    position: fixed;
    background: $primarycolor;
    top: 50%;
    transform: translateY(-50%);
    right: -130px;
    padding: 12px 0 12px 12px;
    z-index: 25;
    border-radius: 6px 0 0 6px;
    display: flex;
    align-items: center;
    color: $white;
    transition: all 300ms ease-in-out;

    &:active,
    &:focus,
    &:hover {
        right: 0;
        color: $white;
        background: lighten($primarycolor, 5%);
    }

    span {
        margin-left: 12px;
        padding-right: 12px;
    }
}

.row--page p {
    margin-bottom: 2rem;
}

.main {
    padding: 0;
}

.layer {
    padding: 95px 0;

    .page__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 35px;

        .page__header__title {
            margin-bottom: 0;
            font-size: 40px;
            line-height: 56px;
            font-weight: 700;
            color: $primarycolor;
        }

        .page__header__link {
            @extend .btn;

            color: $primarycolor !important;
            padding-right: 40px !important;
            cursor: pointer;
            font-size: 20px !important;

            &::before {
                content: '';
                position: absolute;
                right: 17px;
                top: 50%;
                margin-top: -9px;
                width: 7px;
                height: 18px;
                background-image: url('../../svg/angle-right-regular-primary.svg');
                background-size: 7px 18px;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
                color: $primarycolor;
            }
        }
    }
}

.svg-inline--fa {
    width: 30px;
    height: auto;
}

ul {
    &.check {
        margin: 0 0 16px 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            margin-bottom: 43px;
            padding-left: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                top: 4px;
                left: 0;
                width: 18px;
                height: 14px;
                opacity: 1;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.182' height='11.691' viewBox='0 0 15.182 11.691'%3E%3Cpath id='check-purple' d='M14.471-12.1,5.738-3.37,2.528-6.579a.4.4,0,0,0-.564,0l-.939.939a.4.4,0,0,0,0,.564l4.43,4.43a.4.4,0,0,0,.563,0L15.974-10.6a.4.4,0,0,0,0-.563l-.939-.939A.4.4,0,0,0,14.471-12.1Z' transform='translate(-0.909 12.22)' fill='%23ac6788'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 18px 14px;
            }
        }
    }
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primarycolor;
    }
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.archive__content {
    padding: 80px 0 0;
}

.archive__content__intro {
    display: flex;
    justify-content: center;

    .row > .col > p:last-child {
        margin-bottom: 1rem;
    }
}

.archive__list {
    padding: 40px 0;
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -40px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 10px;
}

.slider__top__next {
    right: 10px;
}

.slick-slide {
    cursor: pointer;

    &.slick-current {
        cursor: default;
    }

    a {
        &:focus {
            outline: none !important;
        }
    }

    &:focus {
        outline: none !important;
    }

    img {
        border-radius: $border-radius;
        outline: none !important;

        &:focus {
            outline: none !important;
        }
    }
}

.slider__nav {
    margin: 10px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 4px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.page__socials {
    list-style-type: none;
    display: flex;
    margin-top: 7px;
    padding: 0;
    flex-wrap: wrap;

    li {
        margin-right: 15px;
        margin-bottom: 7px;

        &:last-child {
            margin-right: 0;
        }

        a {
            display: flex;
            justify-content: center;
            align-self: center;
            width: 42px;
            height: 42px;
            background: transparent;
            border: 2px solid $primarycolor;
            color: $primarycolor;
            border-radius: 100%;
            padding: 5px;

            &:hover {
                background: $primarycolor;
                color: $white;

                svg {
                    color: $white;
                }
            }

            svg {
                width: 19px;
                color: $primarycolor;

                &.fa-facebook-f {
                    width: 10px;
                }
            }
        }
    }
}

.block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 40px);
    margin-bottom: 40px;
    border-radius: $block-radius;
    background-color: $white;
    box-shadow: 0 0 15px transparentize(#000, 0.94);

    .block__readmore {
        font-size: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 64px;
        margin-bottom: 15px;

        &::after {
            width: 24px;
            height: 24px;
            background-size: 5px 16px;
            right: 20px;
        }
    }

    &:hover {
        img {
            transform: scale(1.05);
        }

        .block__readmore {
            background-color: lighten($thirdcolor, 5) !important;
        }
    }
}

.block__img__inner {
    display: block;
    overflow: hidden;
    width: 100%;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 0.3s ease;
    }
}

.block__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    padding: 35px 45px 40px 45px;
    color: $body-font-color;
    background-color: $white;

    .btn {
        position: relative;
        display: inline-block;
        margin-top: auto;
        transition: all 0.3s;
        font-size: 16px;

        svg {
            width: 7px;
            height: 18px;
            margin-top: 2px;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
    }
}

.block__title {
    display: block;
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    color: $primarycolor;
}

.block__subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.block__phone,
.block__email {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 34px;
    word-break: break-all;

    svg {
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.block__phone {
    margin-bottom: 4px;
}

.block__no__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #f9f9f9;
}

.block__date {
    display: block;
    margin-top: 7px;
    font-size: 14px;
    font-weight: 700;
    color: #505050;

    svg {
        width: 12px;
        height: 13px;
        margin: -4px 8px 0 0;
        color: #505050;
    }
}

.block__summary {
    display: block;
    height: 100%;
    margin: 15px 0 25px;
    font-size: 17px;
    line-height: 28px;
    color: $body-font-color;
}

.block__list {
    padding: 19px 0 0;
}

.block__item {
    position: relative;
    display: block;
    margin: 0 0 8px 20px;
    color: $body-font-color;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        top: 5px;
        left: -18px;
        width: 6px;
        height: 18px;
        content: "";
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
    }
}

.single__content {
    padding: 95px 0;

    .btn--prev {
        margin-top: 40px;
    }

    h3 {
        margin-top: 30px;
    }
}

.accordion {
    margin: auto;
}

// Custom checkbox styling
.checkbox {
    display: flex;
    align-items: center;
    text-align: left;
}

.checkbox__input {
    display: none;
}

.checkbox__label {
    position: relative;
    display: flex;
    margin: 0;
    padding-right: 20px;
    cursor: pointer;
    line-height: 1.5;

    &::before {
        position: relative;
        display: inline-block;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin: 6px 10px 0;
        content: "";
        cursor: pointer;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        background-color: transparent;
    }
}

.checkbox .checkbox__input:checked + .checkbox__label::after {
    position: absolute;
    top: 8px;
    left: 15px;
    display: block;
    width: 5px;
    height: 9px;
    content: "";
    transform: rotate(45deg);
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
}

.project__detail h1 {
    margin-bottom: 7px;
}

.container-fluid {
    padding-right: 40px;
    padding-left: 40px;

    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

/* stylelint-disable */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .block {
        display: block;
        flex: none;
    }

    .block__content {
        display: block;
    }
}

/* stylelint-enable */

@media (max-width: 1199px) {
    .block__content {
        padding: 25px 35px 30px;
    }

    .slider__top__prev,
    .slider__top__next {
        margin-top: -30px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }
}

@media (max-width: 991px) {
    .page__socials {
        margin-top: 15px;
    }

    .layer {
        .page__header {
            .page__header__title {
                font-size: 32px;
                line-height: 42px;
                display: block;
                width: 100%;
            }

            .page__header__link {
                padding-left: 0 !important;
            }
        }
    }

    .fixed__phone {
        bottom: 15px;
        right: 15px;
        top: unset;
        transform: none;
        padding: 12px;
        border-radius: 6px;

        &:active,
        &:focus,
        &:hover {
            right: 15px;
        }

        svg {
            height: 32px;
            width: 32px;
        }

        span {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    body {
        font-size: 15px;
    }

    .layer {
        padding: 40px 0;
    }

    .layer.archive__list {
        padding-top: 0;
        padding-bottom: 10px;
    }

    .layer.archive__content {
        padding-bottom: 20px;
    }

    .block__summary {
        font-size: 15px;
    }

    .single__content {
        padding: 40px 0;
    }

    .single__content .btn--prev {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {

}

@media (max-width: 374px) {
    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}
