.front__diensten {
    position: relative;
    padding: 0 0 170px;

    .next__slide__button__container {
        position: absolute;
        right: 35px;
        top: 120px;
    }

    .next__slide__button {
        color: #fff;
        cursor: pointer;

        svg {
            width: 23px;
        }
    }

    .container-fluid-offscreen-right {
        margin-left: auto;
        margin-right: 0;
        max-width: calc(1480px + ((100% - 1480px) / 2));
        padding-right: 0;
        position: relative;

        .front__diensten__container__row {
            margin-right: -20px;
            overflow: hidden;
            width: calc(100% + 20px);

            > .col-12 {
                margin-right: -20px;
                padding-right: 0;
            }
        }
    }

    .front__diensten__container {
        max-width: 100vw;
        width: calc(100% + 20px);
        position: relative;
        overflow: hidden;
        margin: 0 0 0 1px;
    }

    .slider-container {
        max-width: 100vw;
        width: 100%;
        overflow: hidden;
    }

    .row {
        .slider {
            width: 800px;
            margin: 0 auto;
        }

        .slick-prev {
            display: none;
        }

        /* Edit, forgot this important bit.  This keeps the other slides visible */

        .slick-list {
            overflow: visible;
            margin: 0 -20px;
        }

        .slick-slide {
            width: 370px;
        }

        .block__home__diensten {
            max-width: calc(300px + 40px) !important;
            width: calc(300px + 40px) !important;
            padding: 0 20px;
            background: transparent;
            cursor: pointer;
            box-shadow: none;
            height: 100%;
            display: flex;
            margin-bottom: 0;

            .block__img__inner {
                border-radius: 50%;
                box-shadow: 0 13px 16px rgba(0, 0, 0, 0.16);

                img {
                    border-radius: 50%;
                }
            }

            .block__img {
                position: relative;
                display: inline-block;
            }

            .block__img__inner__arrow {
                position: absolute;
                right: 26px;
                bottom: 18px;
                width: 44px;
                height: 44px;
                z-index: 1;
                border-radius: 50%;
                background-color: $primarycolor;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 2px;

                svg {
                    width: 9px;
                    color: #fff;
                }
            }

            .block__title {
                font-size: 22px;
                margin-top: 37px;
            }

            .block__content {
                background-color: transparent;
                text-align: center;
                align-items: center;
                padding: 0 25px;
            }
        }
    }
}

@media (max-width: 1480px) {
    .container-fluid-offscreen-right {
        max-width: 100% !important;
        padding-left: 0;
    }

    .front__diensten .row .slick-list {
        margin: 0;
    }
}

@media (max-width: 1199px) {
    .front__diensten {
        padding: 0 0 80px;

        .next__slide__button__container {
            text-align: right;
        }
    }
}

@media (max-width: 991px) {
    .front__diensten .next__slide__button__container {
        right: 85px;
    }
}

@media (max-width: 767px) {
    .front__diensten {
        padding: 0 0 40px;
    }

    .front__diensten .next__slide__button__container {
        right: 60px;
    }
}

@media (max-width: 359px) {
    .front__diensten .next__slide__button__container {
        right: 30px;
    }
}
