/*** Downloads ***/

.searchandfilter {
    margin: 25px 0 0;

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;

        li {
            display: flex;
            align-items: flex-start;
            width: 100%;
            padding: 0;
        }
    }

    > ul > li {
        justify-content: flex-start;
        flex-direction: column;

        li {
            justify-content: flex-end;
            flex-direction: row-reverse;
            padding: 6px 0;

            .checkmark {
                position: relative;
                pointer-events: none;
            }
        }
    }

    label {
        width: 100%;
    }

    h4 {
        font-weight: 600;
        font-size: 24px;
        color: $primarycolor;
        width: 100%;
    }

    .sf-field-search {
        margin-bottom: 25px;

        input {
            margin: 0;
            padding: 8px 15px 8px 15px;
            width: 100%;
            border: 1px solid #eee;
            border-radius: 3px;
            outline: none;
            resize: none;
        }
    }

    .checkcontainer label {
        padding-left: 18px !important;
        font-size: $body-font-size;
        cursor: pointer;
    }
}

.downloads__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.downloads__block {
    .block__title {
        margin: 0 0 19px;
    }

    .block__content {
        padding: 37px 37px 49px 37px;
    }
}

.search-filter-reset {
    background-color: $primarycolor;
    padding: 21px 28px;
    padding-right: 77px;
    cursor: pointer;
    margin-top: 25px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;
    border: none;
    border-radius: $border-radius;
    box-shadow: none;
    transition: all 0.3s;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 34px;
        height: 34px;
        background: lighten(desaturate(adjust-hue($primarycolor, 1), 9.83), 16.86) url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 10) !important;
        outline: none;
        box-shadow: none !important;
        color: #fff;
    }
}

.checkcontainer__group {
    margin: 8px 0 30px;
}

.checkcontainer {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        top: 8px;
        left: 0;
        cursor: pointer;
        opacity: 0;
        height: 18px;
        width: 18px;
        min-width: 18px;
        min-height: 18px;

        &:checked ~ .checkmark::after {
            display: block;
        }
    }

    .checkmark {
        &::after {
            top: 1px;
            left: 5px;
            width: 6px;
            height: 11px;
            transform: rotate(45deg);
            border: solid $primarycolor;
            border-width: 0 3px 3px 0;
        }
    }
}

.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
    background-color: transparent;
    border: 1px solid #cbcbcb;
    border-radius: 3px;

    &::after {
        position: absolute;
        display: none;
        content: "";
    }
}

@media screen and (min-width: 767px) and (max-width: 1340px) {

}

@media screen and (max-width: 767px) {
    .downloads__list {
        .col-xl-4 {
            .btn--download {
                padding: 16px 23px;
                padding-right: 50px;
            }
        }

        .downloads__block .block__content {
            padding: 25px 25px 35px 25px;
        }
    }

    .downloads__block .block__title {
        font-size: 19px;
        line-height: 30px;
    }
}

@media screen and (max-width: 580px) {
    .downloads__list {
        .col-xl-4 {
            max-width: 375px;
            width: 100%;
            flex: 0 0 100%;
        }
    }
}
