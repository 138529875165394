.front__team {
    padding: 0 0 200px;
    position: relative;

    .front__team__circle {
        display: block;
        position: absolute;
        top: 25%;
        transform: translateY(-65%);
        transform-origin: left;
        left: 0;
        max-width: 100%;
        z-index: -1;

        svg {
            max-width: 100%;
        }
    }

    .front__team__title {
        color: $thirdcolor;
        font-size: 59px;
        font-weight: 500;
        margin-bottom: 40px;
    }

    .front__team__content {
        padding-top: 90px;
        margin-bottom: 40px;
    }
}

@media (max-width: 1199px) {
    .front__team {
        padding: 0 0 80px;

        .front__team__title {
            font-size: 51px;
        }

        .front__team__content {
            padding-top: 50px;
        }
    }
}

@media (max-width: 991px) {
    .front__team {
        padding: 0 0 60px;

        .front__team__title {
            margin-bottom: 30px;
            font-size: 38px;
        }

        .front__team__content {
            padding-top: 0;
        }
    }
}

@media (max-width: 767px) {
    .front__team {
        padding: 0 0 40px;
    }
}

@media screen and (max-width: 640px) {
    .front__team .front__team__title {
        font-size: 26px;
    }
}
