.banner {
    position: relative;
    max-width: 100%;
    border-radius: $border-radius;
    overflow: hidden;

    iframe {
        display: table;
    }

    video {
        width: 100%;
        height: auto;
    }

    img {
        width: 100%;
    }

    .slick-slide {
        img {
            border-radius: 0;
        }
    }
}

.banner__home__img {
    img {
        border-radius: 48px;
        box-shadow: 0 0 36px 0 rgba(0, 0, 0, 0.16);
    }
}

.banner__home__content {
    color: $primarycolor;
    padding-left: 140px;
    margin-top: 22px;
}

.banner__home__title {
    font-size: 44px;
    line-height: 1.2;
}

.banner__home__subtitle {
    font-size: 31px;
    line-height: 1.4;
    font-weight: 400;
    margin: 26px 0 38px 0;
}

.slick-dotted {
    &.slick-slider {
        margin-bottom: 0;
    }
}

.slick-dots {
    bottom: 50px;

    li {
        width: 13px;
        height: 13px;
        margin: 0 3px;

        button {
            width: 13px;
            height: 13px;
            padding: 0;

            &::before {
                content: "";
                width: 13px;
                height: 13px;
                line-height: 20px;
                text-align: center;
                border: 2px solid #fff;
                border-radius: 50%;
                opacity: 1;
            }
        }

        &.slick-active {
            button {
                &::before {
                    opacity: 1;
                    background-color: #fff;
                }
            }
        }
    }
}

.banner__slider {
    line-height: 0;
}

.banner__content {
    position: absolute;
    left: 0;
    bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 0 30px;
    color: #fff;

    .btn {
        margin-top: 25px;
    }
}

.banner__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 0 0 12px rgba(0, 0, 0, 0.29);
    text-transform: uppercase;
}

.banner__subtitle {
    font-size: 24px;
    font-weight: 400;
    text-shadow: 0 0 12px rgba(0, 0, 0, 0.29);
}

@media screen and (max-width: 1500px) {
    .banner__home__content {
        padding-left: 50px;
    }
}

@media screen and (max-width: 1300px) {
    .banner__home__content {
        padding-left: 40px;
    }

    .banner__home__title {
        font-size: 40px;
    }

    .banner__home__subtitle {
        font-size: 28px;
    }
}

@media screen and (max-width: 1199px) {
    .banner {
        iframe {
            max-height: 350px;
        }
    }

    .banner__home__content {
        padding-left: 20px;
    }

    .banner__home__title {
        font-size: 35px;
    }

    .btn--pink {
        text-align: left;
        line-height: 1.3;
    }
}

@media screen and (max-width: 991px) {
    .banner__home__content {
        padding-left: 0;
        margin-top: 40px;
    }

    .banner__home__title {
        font-size: 35px;
    }

    .banner__home__subtitle {
        margin: 6px 0 22px 0;
    }
}

@media screen and (max-width: 767px) {
    .banner__content {
        display: none;
    }

    .slick-dots {
        bottom: 20px;
    }

    .banner {
        border-radius: 30px;

        iframe {
            max-height: 230px;
        }
    }

    .banner__home__img img {
        border-radius: 28px;
    }

    .banner__home__content {
        margin-top: 30px;
    }

    .banner__home__title {
        font-size: 26px;
    }

    .banner__home__subtitle {
        font-size: 18px;
    }

    .btn--pink {
        display: block;
    }
}
