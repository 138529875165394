.CybotCookiebotDialogBodyButton {
    padding: 8px 18px 10px !important;
    margin-bottom: 10px !important;
    border-radius: 4px !important;
    font-size: 13px !important;
}

#CybotCookiebotDialogBodyContent {
    margin-top: 6px !important;
    padding-top: 12px !important;
}

#CybotCookiebotDialogPoweredbyLink {
    margin-top: 16px !important;
}

#CybotCookiebotDialogDetail {
    margin-bottom: 20px !important;
}

#CybotCookiebotDialogBodyLevelButtonAccept {
    padding: 4px 18px 6px !important;
    font-size: 13px !important;
    margin-top: 5px !important;
    border-radius: 3px !important;
}

#CybotCookiebotDialogBodyLevelButtonsTable {
    margin: 5px 0 15px 0 !important;
}

@media (max-width: 575px) {
    #CybotCookiebotDialogBody {
        padding-left: 17px !important;
        padding-right: 17px !important;
    }

    #CybotCookiebotDialogBodyLevelButtonAccept {
        margin-bottom: 5px !important;
    }
}
