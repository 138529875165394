.page__faq__layer {
    padding-top: 0;
    padding-bottom: 60px;

    .page__faq__layer__hr {
        margin: 60px 0;
        position: relative;

        &::before {
            content: '';
            background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
            position: absolute;
            left: 0;
            top: -1px;
            height: 3px;
            width: 50%;
        }

        &::after {
            content: '';
            background: linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
            position: absolute;
            right: 0;
            top: -1px;
            height: 3px;
            width: 50%;
        }
    }

    .card-header + div {
        position: absolute;
        z-index: 1;
        left: -1px;
        top: calc(100% - 5px);
        width: calc(100% + 2px);
        border-radius: 0 0 $small-radius $small-radius;
        background: $white;
        border: 1px solid #d5d5d5;
        border-top: 0;
    }
}

.accordion {
    margin-bottom: 30px;

    .card {
        background: $white;
        color: $primarycolor;
        margin-bottom: 30px;
        border-radius: $small-radius;
        border: 1px solid #d5d5d5;

        &:last-of-type,
        &:not(:first-of-type):not(:last-of-type),
        &:first-child {
            border-radius: $small-radius;
            border-bottom: 1px solid #d5d5d5;
        }
    }
}

.card-header {
    border-bottom: none;
    font-weight: 700;
    font-size: 18px;
    background: $white;
    padding: 0;

    &:first-child {
        border-radius: $small-radius;
    }

    a {
        color: $primarycolor;
        background: $white;
        padding: 25px 40px 25px 35px;
        border-radius: $small-radius;
        display: block;

        &::before {
            content: "";
            float: right;
            width: 15px;
            height: 15px;
            background: url('../../svg/angle-down-purple.svg') no-repeat center;
            background-size: 15px;
            margin: 5px 3px 5px 15px;
            transition: all 0.3s;
        }

        &[aria-expanded="false"]::before {
            transform: rotate(0deg);
        }

        &[aria-expanded="true"]::before {
            transform: rotate(-180deg);
        }
    }
}

.card-body {
    padding: 0 40px 30px 35px;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.faq__filter__title {
    font-weight: 600;
    font-size: 24px;
    color: $primarycolor;
}

@media screen and (max-width: 991px) {
    .page__faq__layer {
        padding-bottom: 30px;

        .page__faq__layer__hr {
            margin: 45px 0;
        }

        .card-header + div {
            position: static;
            border: 0;
            width: 100%;
        }
    }
}

@media screen and (max-width: 767px) {
    .card-header a {
        padding: 20px 25px;
    }

    .card-body {
        padding: 0 20px 20px;
    }

    .accordion {
        margin-top: 15px;

        .card {
            margin-bottom: 20px;
        }
    }
}
