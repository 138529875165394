.front__juicer {
    position: relative;
    padding: 0 0 170px;

    .front__juicer__wrap {
        .juicer-feed {
            overflow: visible;

            .referral {
                display: none;
            }

            &.white,
            &.user,
            &.juicer-widget,
            &.colors,
            &.gray,
            &.polaroid,
            &.image-grid,
            &.modern {
                .feed-item {
                    border: 0;
                    border-radius: 15px;
                    overflow: hidden;
                    box-shadow: 0 0 12px transparentize(#000, 0.83);
                }

                .j-stacker-wrapper {
                    position: relative;
                }
            }

            .j-paginate {
                display: none;
            }
        }
    }
}

@media (max-width: 1199px) {
    .front__juicer {
        padding: 0 0 80px;
    }
}

@media screen and (max-width: 991px) {
    .layer .page__header .page__title--juicer {
        width: auto;
    }
}

@media (max-width: 767px) {
    .front__juicer {
        padding: 0 0 40px;
    }

    .layer .page__header .page__title--juicer {
        font-size: 26px;
    }
}
