.btn {
    position: relative;
    padding: 21px 28px;
    border: 0;
    border-radius: $border-radius;
    margin-right: 8px;
    color: #fff;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
        color: #fff;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: $primarycolor;
    padding-right: 77px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 34px;
        height: 34px;
        background: lighten(desaturate(adjust-hue($primarycolor, 1), 9.83), 16.86) url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }

    &.btn--no-arrow {
        padding: 21px 28px;

        &::after {
            display: none;
        }
    }
}

.btn--secondary {
    background-color: $thirdcolor;
    padding-right: 77px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 34px;
        height: 34px;
        background: darken($thirdcolor, 5%) url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($thirdcolor, 5) !important;
    }
}

.btn--next {
    background-color: $primarycolor;
    padding-right: 77px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 34px;
        height: 34px;
        background: lighten(desaturate(adjust-hue($primarycolor, 1), 9.83), 16.86) url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    color: $primarycolor;
    padding-left: 0;
    cursor: pointer;
    font-size: 20px;

    &::before {
        content: '';
        display: inline-block;
        margin-right: 15px;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../svg/angle-left-regular-primary.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
        color: $primarycolor;
    }
}

.btn--open {
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        color: #fff !important;
        border-color: #fff;
    }
}

.btn--download {
    background-color: $thirdcolor;
    padding: 16px 28px;
    padding-right: 77px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 28px;
        height: 28px;
        background: darken($thirdcolor, 5%) url('../../svg/download-regular-white.svg');
        background-size: 15px 13px;
        background-position: center 7px;
        background-repeat: no-repeat;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($thirdcolor, 5) !important;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

.btn--pink {
    background-color: transparent;
    padding-right: 77px;
    cursor: pointer;
    color: #ac6788;
    border: 3px solid #ac6788;
    word-break: break-word;

    &::after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        width: 34px;
        height: 34px;
        background: #ac6788 url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten(#ac6788, 5) !important;
        color: #fff;
    }
}

@media screen and (max-width: 767px) {
    .btn {
        font-size: 16px;
    }
}
