$frontpage-header-padding-extra-height-bottom: 40px;
$frontpage-logo-extra-height: 40px;

.header__circle {
    position: absolute;
    top: -275px;
    right: 0;

    &.header__circle--frontpage {
        top: 0;
        right: 0;
    }
}

.main {
    margin-top: $header-height;

    &.main--frontpage {
        margin-top: $header-height + $frontpage-logo-extra-height + $frontpage-header-padding-extra-height-bottom;
    }
}

.header__top {
    transition: all 300ms ease-in-out;
    background-image: url('../../images/repeat-figures.png');
    background-repeat: repeat-x;
    background-size: 225px;
    display: block;
    height: $header-top-height;
    width: 100%;
}

.header__right__bottom,
.header__right__top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header__right__top {
    height: $phone-height;
    overflow: hidden;
    transition: all 300ms ease-in-out;

    .header__right__top__phone {
        font-size: 24px;
        color: $secondarycolor;
        font-weight: 700;
        line-height: 29px;

        svg {
            width: $phone-height;
            height: $phone-height;
            margin-right: 20px;
        }
    }
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    transition: all 0.3s;

    &.header--frontpage {
        .header__logo {
            img,
            svg {
                height: $logo-height + $frontpage-logo-extra-height;
                width: auto;
                transition: all 0.3s;
            }
        }

        .header__inner {
            padding: $header-padding 0 $header-padding + $frontpage-header-padding-extra-height-bottom 0;
        }
    }

    &.header--fix {
        background: rgba(255, 255, 255, 0.9);

        .header__right__top,
        .header__top {
            height: 0;
        }

        .header__inner {
            padding: 10px 0;
        }

        .header__logo {
            img,
            svg {
                height: 90px;
                width: auto;
            }
        }

        .header__menu > ul > li > a {
            height: 80px;
        }
    }
}

.header__logo {
    img,
    svg {
        height: 160px;
        width: auto;
        transition: all 0.3s;
    }
}

.header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $header-padding 0;
}

.header__icons {
    display: flex;
    align-items: stretch;
    justify-content: center;

    svg {
        width: 17px;
        height: 17px;
        color: $primarycolor;

        &.fa-times {
            width: 15px;
            height: 17px;
            position: absolute;
            top: 13px;
            right: 10px;
        }
    }
}

.header__phone {
    display: none;
    padding: 0 7px;
    align-items: center;
    justify-content: center;
}

.header__search {
    cursor: pointer;
    padding: 0 7px;

    &.open {
        .header__search__input {
            right: calc(100% + 10px);
            pointer-events: all;
            opacity: 1;
            transition: all 300ms ease-in-out;

            .header__search__toggle {
                display: block;
            }
        }

        .header__search__toggle {
            display: none;
        }

        .header__search__submit {
            display: flex;
        }
    }

    form {
        position: relative;
        display: flex;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 35px;
        height: 42px;
        margin: 0;
    }

    .header__search__input {
        position: absolute;
        width: 250px;
        right: 0;
        font-size: 14px;
        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease-in-out;

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 0 5px 7px;
            border-color: transparent transparent transparent #eee;
            position: absolute;
            left: 100%;
            top: calc(50% - 4px);
        }

        .search-field {
            border: 1px solid #eee;
        }
    }

    .header__search__toggle {
        display: flex;
    }

    .header__search__submit {
        display: none;
    }
}

.menu-item {
    position: relative;

    &.menu-item-home {
        a {
            visibility: hidden;

            &::before {
                content: '';
                position: absolute;
                padding: 10px;
                width: 100%;
                height: 100%;
                background: url('../../svg/home-light-primary.svg') no-repeat;
                background-size: 24px;
                background-position: center center;
                visibility: initial;
            }
        }
    }

    &.open {
        > .menu-item-touch {
            display: none;
        }

        > .sub-menu {
            display: block;
        }
    }
}

.header__menu__btn {
    display: none;
}

.menu-item-touch {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: block;
    background-color: transparent;
}

.no-touchevents {
    .menu-item-touch {
        display: none;
    }

    .header__menu {
        .menu-item {
            &:hover,
            &:focus {
                > .sub-menu {
                    display: block;
                }
            }
        }

        > ul {
            > li {
                &:hover,
                &:focus {
                    > .sub-menu {
                        display: block;
                    }
                }
            }
        }
    }
}

// Header menu
.header__menu {
    a {
        font-size: 21px;
        font-weight: 500;
        color: $primarycolor;
    }

    .active {
        > a {
            color: $primarycolor;
        }
    }

    .menu-item {
        position: relative;

        //&:hover,
        //&:focus {
        //    > .sub-menu {
        //        display: block;
        //    }
        //}
    }

    .menu-angle {
        .svg-inline--fa {
            width: 14px;
            height: 25px;
        }
    }

    .sub-menu {
        position: absolute;
        margin: 0;
        padding: 0;
        list-style: none;
        display: none;

        li {
            &:last-child {
                a {
                    border-bottom: 1px solid $primarycolor;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px 10px 20px;
            font-size: 15px;
            font-weight: 300;
            color: #fff;
            width: 100%;
            min-height: 46px;
            min-width: 250px;
            border-bottom: 1px solid lighten($primarycolor, 5);
            background-color: $primarycolor;
        }

        .menu-angle {
            margin-left: 10px;

            .svg-inline--fa {
                width: 8px;
                height: 22px;
            }
        }
    }

    > ul {
        > li {
            &:last-child {
                a {
                    padding-right: 0;
                }
            }

            &:hover,
            &:focus {
                > a {
                    color: $primarycolor;
                    opacity: 0.7;
                }

                //> .sub-menu {
                //    display: block;
                //}
            }

            > a {
                height: $nav-height;
                padding: 0 25px;
                display: flex;
                align-items: center;
                justify-content: center;

                > .menu-angle {
                    margin-left: 10px;
                }
            }

            > .sub-menu {
                top: 100%;
                left: 0;

                > li {
                    &.active {
                        > a {
                            background-color: darken($primarycolor, 4);
                        }
                    }

                    &:hover,
                    &:focus {
                        > a {
                            background-color: darken($primarycolor, 4);
                        }
                    }
                }

                .sub-menu {
                    top: 0;
                    left: 100%;

                    li {
                        > a {
                            background-color: darken($primarycolor, 4);
                        }

                        &.active {
                            > a {
                                background-color: darken($primarycolor, 8);
                            }
                        }

                        &:hover,
                        &:focus {
                            > a {
                                background-color: darken($primarycolor, 8);
                            }
                        }

                        &:last-child {
                            a {
                                border-bottom: 1px solid darken($primarycolor, 4);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    .header__menu {
        .sub-menu {
            display: inherit;
            visibility: hidden;
            opacity: 0;
        }
    }

    .no-touchevents {
        .header__menu {
            .menu-item {
                &:hover,
                &:focus {
                    > .sub-menu {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            > ul {
                > li {
                    &:hover,
                    &:focus {
                        > .sub-menu {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1399px) {
    .header__menu > ul > li > a {
        padding: 0 20px;
    }
}

@media screen and (max-width: 1299px) {
    .header__menu > ul > li > a {
        padding: 0 15px;
        font-size: 19px;
    }
}

@media screen and (max-width: 1199px) {
    .header__circle {
        width: 105%;
        overflow: hidden;

        &.header__circle--frontpage {
            top: 0;
        }
    }

    .menu-item.menu-item-home a::before {
        background-size: 21px;
    }

    .header__menu > ul > li > a {
        padding: 0 15px;
        font-size: 16px;
    }

    .header__icons {
        margin-left: 10px;
    }

    .header__logo {
        img,
        svg {
            height: 140px;
        }
    }
}

@media screen and (max-width: 1099px) {
    .header {
        &.header--frontpage {
            .header__logo {
                img,
                svg {
                    width: 260px;
                }
            }
        }
    }

    .header__menu > ul > li > a {
        padding: 0 12px;
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {
    $header-top-height: 0;
    $header-height: $nav-height + $header-top-height;

    .header__circle {
        &.header__circle--frontpage {
            top: -275px;
        }
    }

    .menu-item.menu-item-home a::before {
        border-bottom: 1px solid;
        background-position: left;
    }

    .header__top {
        background-size: 150px;
        height: $header-top-height;
    }

    .header__right__top {
        display: none;
    }

    .header__search .btn {
        width: 25px;
    }

    .header__icons {
        margin-left: 5px;
    }

    .header__icons svg {
        width: 15px;
        height: 15px;
    }

    .header__search {
        padding: 0 5px;
    }

    .header__phone {
        display: flex;
        padding: 0 5px;
        margin-right: 15px;
    }

    body {
        &.header__menu__open {
            position: fixed;

            .header__menu {
                opacity: 1;
                pointer-events: all;
                transition: opacity 300ms ease-in-out;
            }

            .header__menu__bar--top {
                transform: rotate(45deg);
                top: 1px;
                transition: all 300ms ease-in-out;
            }

            .header__menu__bar--mid {
                opacity: 0;
                transition: all 300ms ease-in-out;
            }

            .header__menu__bar--bot {
                transform: rotate(-45deg);
                bottom: 1px;
                transition: all 300ms ease-in-out;
            }

            .header__menu__btn__inner {
                height: 16px;
                transition: all 300ms ease-in-out;
            }
        }
    }

    .main.main--frontpage,
    .main {
        margin-top: $header-height + 10px;
    }

    .header {
        background: #ebdae2;

        &.header--frontpage {
            .header__logo {
                img,
                svg {
                    height: 90px;
                    width: auto;
                }
            }

            .header__inner {
                padding: 0;
                height: $nav-height;
            }
        }
    }

    .header__inner {
        padding: 0;
        height: $nav-height;
    }

    .header__logo {
        img,
        svg {
            height: 90px;
            width: auto;
        }
    }

    .header__menu__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
    }

    .header__menu__btn__inner {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: 12px;
        width: 18px;
        position: relative;
        transition: all 300ms ease-in-out;
    }

    .header__menu__bar {
        width: 18px;
        height: 2px;
        background-color: $primarycolor;
        position: absolute;
        transform: rotate(0deg);
        opacity: 1;
        transition: all 300ms ease-in-out;

        &.header__menu__bar--top {
            top: 0;
            left: 0;
            transform-origin: top left;
        }

        &.header__menu__bar--mid {
            top: 50%;
            left: 0;
            margin-top: -1px;
        }

        &.header__menu__bar--bot {
            bottom: 0;
            left: 0;
            transform-origin: bottom left;
        }
    }

    .header__menu a {
        color: $primarycolor;
    }

    .header__menu .active > a {
        color: $primarycolor;
    }

    .header__menu {
        position: fixed;
        top: $header-height;
        left: 0;
        width: 100%;
        height: calc(100% - 70px);
        overflow: auto;
        background-color: #ebdae2;
        z-index: 9999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;
    }

    .header__menu .nav {
        flex-direction: column;
        padding: 20px 40px;
    }

    .header__menu > ul > li > a {
        height: auto;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 40px 12px 0;
        border-bottom: 1px solid lighten($primarycolor, 5);
        font-size: 16px;
    }

    .header__menu > ul > li > a > .menu-angle {
        position: absolute;
        right: 0;
        top: 0;
        height: 52px;
        width: 40px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header__menu .menu-angle .svg-inline--fa {
        width: 20px;
        height: 22px;
        transform: rotate(0deg);
        transition: transform 300ms ease-in-out;
    }

    .touchevents .menu-item-touch,
    .no-touchevents .menu-item-touch {
        display: block;
        position: absolute;
        left: initial;
        right: 0;
        top: 0;
        width: 40px;
        height: 52px;
    }

    .no-touchevents .header__menu .menu-item:hover > .sub-menu,
    .no-touchevents .header__menu .menu-item:focus > .sub-menu {
        display: none;
    }

    .header__menu > ul > li:hover > a,
    .header__menu > ul > li:focus > a {
        color: $primarycolor;
    }

    .header__menu .sub-menu {
        position: relative;
        top: initial !important;
        left: initial !important;

        .menu-angle {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    .header__menu .sub-menu a {
        padding: 10px 40px 10px 20px;
        font-size: 16px;
        font-weight: 600;
        min-height: 52px;
        justify-content: flex-start;
    }

    .header__menu .sub-menu a::before {
        content: '';
        // KLEUR VERANDEREN: in background data image: je ziet hier een style='color: ;'. Plaats de kleurcode achter %23. (%23 staat voor #)
        background-image: url("data:image/svg+xml,%3Csvg style='color: %2366A1BE;' aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' class='svg-inline--fa fa-angle-right fa-w-6' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='currentColor' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z'%3E%3C/path%3E%3C/svg%3E");
        background-size: 6px;
        background-repeat: no-repeat;
        width: 7px;
        height: 15px;
        margin-right: 7px;
    }

    .header__menu .sub-menu a .menu-angle {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu-item.open > .menu-item-touch {
        display: block;
    }

    .menu-item.open > .sub-menu {
        display: block !important;
    }

    .menu-item.open > a .menu-angle svg {
        transform: rotate(180deg);
        transition: transform 300ms ease-in-out;
    }

    .sub-menu > .menu-item.open > a .menu-angle svg {
        transform: rotate(270deg);
        transition: transform 300ms ease-in-out;
    }

    .header__menu > ul > li > .sub-menu .sub-menu li > a {
        background-color: darken($primarycolor, 8);
        padding: 0 35px;
    }

    .header__menu > ul > li > .sub-menu > li a {
        background-color: darken($primarycolor, 4);
    }
}

@media screen and (max-width: 767px) {
    .header {
        position: absolute;
        width: 100vw;
    }
}

@media screen and (max-width: 575px) {

}

@media screen and (max-width: 420px) {
    .header__menu .nav {
        padding: 10px 20px;
    }

    .header__menu > ul > li > a {
        font-size: 15px;
    }

    .header__menu .sub-menu a {
        min-width: auto;
        font-size: 14px;
    }

    .header__search .header__search__input {
        width: 190px;
    }
}
