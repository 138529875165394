.image__block {
    .image__block__item {
        overflow: hidden;
        position: relative;
        display: block;
        border-radius: $image-radius;
        margin-bottom: 40px;
        transition: all 300ms ease-in-out;

        &:hover {
            box-shadow: 0 0 15px transparentize(#000, 0.9);

            .image__block__media {
                img {
                    transform: scale(1.05);
                }
            }
        }

        .image__block__media {
            z-index: 5;
            position: relative;
            height: 340px;
            display: block;

            img {
                transition: all 300ms ease-in-out;
                object-fit: cover;
                height: 100%;
                width: 100%;
                object-position: center;
            }
        }

        &::before {
            z-index: 10;
            content: '';
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
        }

        &::after {
            z-index: 10;
            content: '';
            width: 46px;
            height: 46px;
            position: absolute;
            bottom: 18px;
            right: 18px;
            border-radius: 100%;
            background: url('../../svg/angle-right-regular-white.svg') no-repeat;
            background-color: $thirdcolor;
            background-size: 9px;
            background-position: center;
        }

        .image__block__content {
            z-index: 20;
            color: $white;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px 75px 25px 45px;
            width: 100%;

            span,
            p {
                display: block;
                margin: 0;
            }
        }

        .image__block__title {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 10px;
            display: block;
            font-weight: 700;
        }

        //Team item specific
        &.team__item {
            .team__item__specialism {
                font-size: 15px;
            }

            .team__item__function {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .image__block {
        .image__block__item {
            margin-bottom: 30px;
        }
    }
}
