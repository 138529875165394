.team__function {
    font-size: 19px;
    font-weight: 400;
    margin: -10px 20px 20px 0;
    color: $secondarycolor;
    line-height: normal;
}

.team__specialism {
    font-size: 15px;
    font-weight: 400;
    margin: -10px 20px 27px 0;
    color: $secondarycolor;
    line-height: normal;
}

.team__quote {
    position: relative;

    .team__quote__left {
        position: absolute;
        top: -19px;
        left: 0;
    }

    .team__quote__right {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .team__quote__line {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.179em;
        color: $primarycolor;
        text-align: center;
        padding: 0 50px;
        padding-bottom: 40px;
    }
}

.team__details {
    margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    a {
        color: $body-font-color;
        margin: 0 32px 8px 0;
        display: flex;
        align-items: center;

        svg {
            margin: 0 10px 0  0;
            width: 18px;
            height: 18px;
            color: $primarycolor;
            fill: $primarycolor;
        }
    }
}

.team__singleimg {
    max-width: 444px;
    margin-bottom: 42px;

    img {
        border-radius: $border-radius;
    }
}

.sidebar__team {
    max-width: 450px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .team__quote {
        .team__quote__left {
            top: -9px;

            svg {
                width: 22px;
            }
        }

        .team__quote__right {
            bottom: 0;

            svg {
                width: 22px;
            }
        }

        .team__quote__line {
            font-size: 21px;
            padding-bottom: 10px;
        }
    }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {
    .team__quote__line {
        font-size: 18px;
        padding: 0 30px;
        padding-bottom: 10px;
    }
}
